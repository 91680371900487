import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultData = {
	"tag": {},
	"company": [],
	"messenger": [],
	"farm": {},
	"logo": [],
	"farm_image": [],
	"tag_image": [],
	"gallery_image": []
}

// let tagid='def01dc9-7972-4a52-8a49-386ae81c6fec';


export default function Feedback() {
	const [data, setData] = useState(defaultData)
    let { id } = useParams();
	const [ratings, setRatings] = useState({
        tagid: id,
        recommendation: 'unlikely',
        overall: 0,
        texture: 0,
        aroma: 0,
        flavor: 0,
        info_accuracy: 0,
        service_quality: 0,
        value_money: 0,
        name: '',
        phone: '',
        price: '',
        price_kg: '',
        reamark: 'asds',
        last_update: new Date().toISOString(),
    })
	const [images, setImages] = useState([])
	const [imagesBlob, setImagesBlob] = useState([])
    let history = useHistory();
    const [loading, setLoading] = useState(false)

	useEffect(() => {
        setLoading(true)
		axios.get(`https://farmsensapi.cloudatik.com/durian/scan/${id}/`).then(res=>{
            setLoading(false)
			console.log(res.data)
			setData(res.data)
		}).catch(res=>{
            setLoading(false)
			console.log(res)
            alert('Invalid QR')
		})
	}, [])

    function RatingStar({type}) {
        let temp_return = []
        for (let i=0; i<ratings[type]; i++) {
            temp_return.push(
                <span class="fa fa-star text-yellow-500" onClick={()=> {
                    setRatings(prev=> ({
                        ...prev,
                        [type]: i+1
                    }))
                }}></span>
            )
        }
        for (let i=temp_return.length; i<5; i++) {
            temp_return.push(
                <span class="fa fa-star" onClick={()=> {
                    setRatings(prev=> ({
                        ...prev,
                        [type]: i+1
                    }))
                }}></span>
            )
        }
        return temp_return
    }

    function handleSubmit() {
        let bodyFormData = new FormData();
        bodyFormData.append('tag', JSON.stringify( ratings ));
        for (let i of images){
            bodyFormData.append(Object.keys(i)[0], i[Object.keys(i)[0]]);
        }
        axios.post(`https://farmsensapi.cloudatik.com/feedback/insertFeedbackWithImage/`, bodyFormData).then(res=>{
            toast.success(res.data.message, {
                position: "top-center",
                icon:'✔',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                theme: "colored"
            })
            setTimeout(()=>{return history.push(`/${id}`)}, 2500)
            
        }).catch(res=>{
            console.log(res)
            alert('Server Error')
        })

    }

    function handleImage(e) {
        if (e.target.files.length < 1) {
            return null
        }
        
        let counter = 1;
        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                let file = new File([e.target.files[i]], new Date().toISOString() + "_" + counter, {type: "image/jpg", lastModified: Date.now()});
                if (counter < 10) {
                    setImages([...images, {
                        ['0'+counter]: file
                    }])
                } else {
                    setImages([...images, {
                        counter: file
                    }])
                }
                setImagesBlob([...imagesBlob, URL.createObjectURL(e.target.files[i])])
            }
            counter ++
        }

        alert('Image Uploaded')
    }

    return (
        <>
        {loading ? (
            <div class="wrapper max-w-xl mx-auto mb-16">
                <img class="overlay" src={require("../assets/loading.gif").default} style={{position:'absolute',  zIndex:100}}/>
            </div>
        ) : (
            <div>
            <div class="wrapper max-w-xl mx-auto">
                <div class="main-header flex flex-row justify-center bg-white p-2">
                    <a href="/" class="flex-2">
                        <img class="w-20 h-auto" src={data.logo && data.logo[0]}/>
                    </a>
                    <div class="flex-1 self-center">
                        <p class="text-xl brand-name">{data.company[0] ? (data.company[0].name ? data.company[0].name : '-') : '-'}</p>
                    </div>
                </div>


                <div className="feedback-bg-color m-3 mt-0">
                    <div className="bg-gray-400 text-center py-2 section-title text-white">
                        <span>FEEDBACK FORM</span>
                    </div>

                    <div className="row p-4">
                        <p>How likely are you to recommend <b>{data.company[0] ? (data.company[0].name ? data.company[0].name : '-') : '-'}</b> fruits to a friend or colleague?</p>
                        <div className="row flex justify-around mt-3">
                            <label class="inline-flex items-center">
                                <input type="radio" class="form-radio" value="unlikely" checked={ratings.recommendation == 'unlikely'} onChange={()=> {
                                    setRatings(prev=>({
                                        ...prev,
                                        recommendation: 'unlikely'
                                    }))
                                }}/>
                                <span class="ml-2" >Unlikely</span>
                            </label>
                            <label class="inline-flex items-center">
                                <input type="radio" class="form-radio" value="neutral" checked={ratings.recommendation == 'neutral'} onChange={()=> {
                                    setRatings(prev=>({
                                        ...prev,
                                        recommendation: 'neutral'
                                    }))
                                }}/>
                                <span class="ml-2">Neutral</span>
                            </label>
                            <label class="inline-flex items-center">
                                <input type="radio" class="form-radio" value="likely" checked={ratings.recommendation == 'likely'} onChange={()=> {
                                    setRatings(prev=>({
                                        ...prev,
                                        recommendation: 'likely'
                                    }))
                                }}/>
                                <span class="ml-2">Likely</span>
                            </label>
                        </div>
                    </div>

                    <div className="row p-4">
                        <p>The overall flavour and quality of the fruit</p>
                        <div className="row flex justify-around text-3xl m-3">
                            <RatingStar type="overall"/>
                        </div>
                    </div>

                    <div className="row p-4">
                        <p>The texture of the fruit</p>
                        <div className="row flex justify-around text-3xl m-3">
                            <img src={require("../assets/texture.svg").default} class="w-7 mr-2"/>
                            <RatingStar type="texture"/>
                        </div>
                    </div>


                    <div className="row p-4">
                        <p>The aroma of the fruit</p>
                        <div className="row flex justify-around text-3xl m-3">
                            <img src={require("../assets/aroma.svg").default} class="w-7 mr-2"/>
                            <RatingStar type="aroma"/>
                        </div>
                    </div>

                    <div className="row p-4">
                        <p>The taste of the fruit</p>
                        <div className="row flex justify-around text-3xl m-3">
                            <img src={require("../assets/taste.svg").default} class="w-7 mr-2"/>
                            <RatingStar type="flavor"/>
                        </div>
                    </div>
                    <div className="row p-4">
                        <p>The accuracy of fruit information in QR code</p>
                        <div className="row flex justify-around text-3xl m-3">
                            <img src={require("../assets/accuracy.svg").default} class="w-7 mr-2"/>
                            <RatingStar type="info_accuracy"/>
                        </div>
                    </div>
                    <div className="row p-4">
                        <p>The quality of service</p>
                        <div className="row flex justify-around text-3xl m-3">
                            <img src={require("../assets/service.svg").default} class="w-7 mr-2"/>
                            <RatingStar type="service_quality"/>
                        </div>
                    </div>
                    <div className="row p-4">
                        <p>Value of Money</p>
                        <div className="row flex justify-around text-3xl m-3">
                            <img src={require("../assets/value.svg").default} class="w-7 mr-2"/>
                            <RatingStar type="value_money"/>
                        </div>
                    </div>
                    <div className="row p-4 ">
                        <p>Share your experience and help others make better choices!</p>
                        <div class="my-2">
                            <textarea style={{border: '1px solid black', borderRadius: '5px'}} id="reamark" name="reamark" rows="4" class="w-full" onChange={(e)=>{
                                setRatings(prev=> ({
                                    ...prev,
                                    reamark: e.target.value
                                }))
                            }}></textarea>
                        </div>
                        <div class="flex center-AI">
                            <div class="w-1/2">
                                <p id="additional-feedback">Please provide additional feedback</p>
                            </div>
                            <div className="goldenrod flex justify-center w-1/2 p-0.5">
                                <img src={require("../assets/upload.svg").default} class="w-5" id="upload-svg"/>

                                <button className="btn btn-primary" id="add-photos-btn">
                                    <label class="self-center text-white leading-none" htmlFor="img" style={{margin:0}}>
                                    <span class="leading-none">ADD PHOTO(S)</span>
                                        <input
                                            accept="image/*"
                                            id="img"
                                            multiple
                                            name="img"
                                            type="file"
                                            capture
                                            onChange={handleImage}
                                            hidden
                                        />
                                    </label>
                                </button>
                            </div>

                        </div>

                        <div className="flex flex-wrap my-3">
                            {imagesBlob.map(image=>(
                                <img class="w-20 h-auto m-1" src={image}/>
                            ))}
                        </div>
                    </div>

                    <div className="row p-4">
                        <p class="text-2xl">Customer Purchase and Feedback Records</p>
                    </div>
                    <div className="row p-4">
                        <p className="mb-2">Customer Name</p>
                        <input style={{border: '1px solid black', borderRadius: '5px'}} type="text" class=" w-full" value={ratings.name} onChange={(e) => {
                            setRatings(prev=> ({
                                ...prev,
                                name: e.target.value
                            }))
                        }}/>
                    </div>
                    <div className="row p-4">
                        <p className="mb-2">Mobile phone No*</p>
                        <input style={{border: '1px solid black', borderRadius: '5px'}} type="number" class=" w-full" value={ratings.phone} onChange={(e) => {
                            setRatings(prev=> ({
                                ...prev,
                                phone: e.target.value
                            }))
                        }}/>
                    </div>
                    <div className="row p-4">
                        <p>* URL record will send to Customer Mobile Phone Num and registered mobile number will be used as login ID</p>
                    </div>
                    <div className="row p-4">
                        <p className="mb-2">Purchase Price (RM)</p>
                        <input style={{border: '1px solid black', borderRadius: '5px'}} type="text" class=" w-full" onChange={(e) => {
                            setRatings(prev=> ({
                                ...prev,
                                price: e.target.value
                            }))
                        }}/>
                    </div>
                    <div className="row p-4">
                        <p className="mb-2">Unit Price (RM/kg)</p>
                        <input style={{border: '1px solid black', borderRadius: '5px'}} type="text" class=" w-full" value={ratings.unit_price} onChange={(e) => {
                            setRatings(prev=> ({
                                ...prev,
                                price_kg: e.target.value
                            }))
                        }}/>
                    </div>
                    
                    <div className="footer w-full p-2">
                        <div className="yellowgreen flex p-3 justify-center" onClick={()=>{
                            handleSubmit()
                        }}>
                            <img src={require("../assets/feedback.svg").default} class="w-7 mr-2"/>
                            <p class="self-center text-white text-lg">SUBMIT FEEDBACK</p>
                        </div>
                    </div>


                </div>
                

            </div>
            <ToastContainer />
            </div>
        )}
        </>
    )
}



