import logo from '../logo.svg';
import '../App.css';
import React, {useEffect, useState} from 'react';
import MapContainer from '../components/MapContainer';
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';

const defaultData = {
	"tag": {},
	"company": [],
	"messenger": [],
	"farm": {},
	"logo": [],
	"farm_image": [],
	"tag_image": [],
	"gallery_image": []
}

export default function Landing() {
	const [data, setData] = useState(defaultData)
    let { id } = useParams();
    const [loading, setLoading] = useState(false)

	useEffect(() => {
        setLoading(true)
		axios.get(`https://farmsensapi.cloudatik.com/durian/scan/${id}/`).then(res=>{
            setLoading(false)
			console.log(res.data)
			setData(res.data)
		}).catch(res=>{
            setLoading(false)
			console.log(res)
            alert('Invalid QR')
		})
	}, [])

    function getOrientationLandscape(e){
        const { innerWidth: width, innerHeight: height } = window;

        e.target.style.overflow = 'hidden'
        if (width > 580 ) {
            e.target.style.maxHeight = '250px'
        } else {
            e.target.style.maxHeight = '200px'
        }
        if (e.target.naturalHeight > e.target.naturalWidth) {
            // "portrait";
            e.target.style.width = 'auto'
            // e.target.style.margin = 'auto'
            e.target.style.height = '100%'
            e.target.style['object-fit'] = 'contain'

        } else {
            e.target.style.width = '100%'
            e.target.style.height = 'auto'
        }
    }

    // function getOrientationPortrait(e) {
    //     const { innerWidth: width, innerHeight: height } = window;
    //     if (e.target.height > e.target.width) {
    //         // return "portrait";
    //         // e.target.style.overflow = 'hidden'
    //         // e.target.style.maxHeight = '240px'
    //         // e.target.style.width = 'auto'
    //         // e.target.style.margin = 'auto'
    //         // e.target.style.height = '100%'
    //     } else {
    //         // e.target.style.overflow = 'hidden'
    //         // e.target.style.maxHeight = '240px'
    //         // e.target.style.width = '100%'
    //         // e.target.style.height = 'auto'
    //         // if (width < 580) {
    //         //     e.target.style.paddingTop = '80px'
    //         // }
    //     }
        
    // }
   

    return (
        <div>
            {loading ? (
                <div class="wrapper max-w-xl mx-auto mb-16">
                    <img class="overlay" src={require("../assets/loading.gif").default} style={{position:'absolute',  zIndex:100}}/>
                </div>
            ) : (
                <div class="wrapper max-w-xl mx-auto mb-16">
                    <div class="main-header flex flex-row justify-center p-2" >
                        <Link to={`/${id}`} class="flex-2">
                            <img class="w-20" src={data.logo && data.logo[0]}/>
                        </Link>
                        <div class="flex-1 self-center">
                            <p class="text-xl brand-name">{data.company[0] ? (data.company[0].name ? data.company[0].name : '-') : '-'}</p>
                        </div>
                    </div>

                    <div className="body">
                        <div className="image-container w-100 bg-black">
                                <Carousel showThumbs={false} >
                                    {(data.gallery_image && data.gallery_image.length>0) && data.gallery_image.map(image => {
                                        return (
                                            <div>
                                                <img src={image} className="carousel-cover"/>
                                            </div>
                                        )
                                    })}
                                </Carousel>
                        </div>


                        <div className="seperator h-2 text-gray-600 flex justify-center items-center"></div>
                        
                        <div className="fruit-container mx-2">
                            <div className="fruit-header py-2 section-title text-center text-white">
                                <p>FRUIT'S INFORMATION</p>
                            </div>

                            <div className="fruit-body p-3">
                                <div className="row flex" >
                                    <div className="fruit-image w-3/6 bg-black">
                                        <Carousel showThumbs={false} autoPlay={false}>
                                            {(data.tag_image && data.tag_image.length>0) && data.tag_image.map(image => {
                                                return (
                                                    <div style={{ display:'flex', height: "300px", alignItems: 'center'}}>                                                    
                                                        <img src={image} className="carousel-cover"/>
                                                    </div>
                                                )
                                            })}
                                        </Carousel>
                                    </div>

                                    <div className="w-3/6" id="FI-text-beside-carousel">
                                        <p class="text-2xl font-semibold FI-fruit-name closer-LH">{data.tag.breed||'-'}</p>
                                        <div class="rating my-5">
                                            <i class="fa fa-star fill-current text-yellow-400 " aria-hidden="true"></i>
                                            <i class="fa fa-star fill-current text-yellow-400 " aria-hidden="true"></i>
                                            <i class="fa fa-star fill-current text-yellow-400 " aria-hidden="true"></i>
                                            <i class="fa fa-star fill-current text-yellow-400 " aria-hidden="true"></i>
                                            <i class="fa fa-star fill-current text-yellow-400 " aria-hidden="true"></i>
                                            <p class="closer-LH">153 ratings</p>
                                        </div>

                                        <div className="flex">
                                            <img src={require("../assets/origin.svg").default} class="w-7 mr-2" />
                                            <div className="info my-5">
                                                <p class="text-gray-400 FI-details">Origin</p>
                                                <p class="text-base closer-LH">{data.tag.origin||'-'}</p>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <img src={require("../assets/age.svg").default} class="w-7 mr-2"/>
                                            <div className="info">
                                                <p class="text-gray-400 FI-details">Tree Age</p>
                                                <p class="closer-LH">{data.tag.tree_age||'-'} years</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-5" style={{textAlign:'-webkit-center'}}>
                                </div>

                                <div className="row flex ">
                                    <div className="flex mb-4 w-3/6 mr-12px">
                                        <img src={require("../assets/localname.svg").default} class="w-6 mr-2" />
                                        <div className="info">
                                            <p class="text-gray-400 FI-details">Local Name</p>
                                            <p class="closer-LH">{data.tag.other_name||'-'}</p>
                                        </div>
                                    </div>
                                    <div className="flex mb-4 w-3/6">
                                        <img src={require("../assets/grade.svg").default} class="w-7 mr-2" />
                                        <div className="info">
                                            <p class="text-gray-400 FI-details">Grade</p>
                                            <p class="closer-LH">{data.tag.grade||'-'}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row flex">
                                    <div className="flex mb-4 w-3/6 mr-12px">
                                        <img src={require("../assets/taste.svg").default} class="w-6 mr-2" />
                                        <div className="info">
                                            <p class="text-gray-400 FI-details">Taste</p>
                                            <p class="closer-LH">{data.tag.taste||'-'}</p>
                                        </div>
                                    </div>
                                    <div className="flex mb-4 w-3/6">
                                        <img src={require("../assets/color.svg").default} class="w-7 mr-2" />
                                        <div className="info">
                                            <p class="text-gray-400 FI-details">Color</p>
                                            <p class="closer-LH">{data.tag.color||'-'}</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="row flex">
                                    <div className="flex mb-4 w-3/6 mr-12px">
                                        <img src={require("../assets/texture.svg").default} class="w-6 mr-2" />
                                        <div className="info">
                                            <p class="text-gray-400 FI-details">Texture</p>
                                            <p class="closer-LH">{data.tag.texture||'-'}</p>
                                        </div>
                                    </div>
                                    <div className="flex mb-4 w-3/6">
                                        <img src={require("../assets/size.svg").default} class="w-6 mr-2" />
                                        <div className="info">
                                            <p class="text-gray-400 FI-details">Seed Size</p>
                                            <p class="closer-LH">{data.tag.seed_size||'-'}</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="row flex">
                                    <div className="flex mb-4 w-3/6 mr-12px">
                                        <img src={require("../assets/aroma.svg").default} class="w-7 mr-2" />
                                        <div className="info">
                                            <p class="text-gray-400 FI-details">Aroma</p>
                                            <p class="closer-LH">{data.tag.aroma||'-'}</p>
                                        </div>
                                    </div>
                                    <div className="flex mb-4 w-3/6">
                                        <img src={require("../assets/date-harvest.svg").default} class="w-7 mr-2" />
                                        <div className="info">
                                            <p class="text-gray-400 FI-details">Date Harvest</p>
                                            <p class="closer-LH">{data.tag.harvest||'-'}</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="row flex">
                                    <div className="flex mb-4 w-3/6 mr-12px">
                                        <img src={require("../assets/shape.svg").default} class="w-6 mr-2" />
                                        <div className="info">
                                            <p class="text-gray-400 FI-details">Shape</p>
                                            <p class="closer-LH">{data.tag.shape||'-'}</p>
                                        </div>
                                    </div>
                                    <div className="flex mb-4 w-3/6">
                                        <img src={require("../assets/altitude.svg").default} class="w-7 mr-2" />
                                        <div className="info">
                                            <p class="text-gray-400 FI-details">Altitude</p>
                                            <p class="closer-LH">{data.tag.altitude||'-'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="certification-body"></div>

                        <div className="seperator h-2 text-gray-600 flex justify-center items-center"></div>

                        <div className="farm-body mx-2">
                            <div className="farm-header p-3 section-title text-center text-white">
                                <p>FARM STORY</p>
                            </div>
                            <div className="farm-image">
                                <Carousel showThumbs={false} dynamicHeight={true}>
                                    {(data.farm_image && data.farm_image.length>0) && data.farm_image.map(image => {
                                        return (
                                            <div>
                                                <img src={image} onLoad={e=>getOrientationLandscape(e)}/>
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </div>
                            <div className="farm-content p-4">
                                <p class="font-semibold	" id="FS-farm-name">{data.farm.name||'-'}</p>
                                <p class="mb-3 text-xl">{data.farm.address||'-'}</p>
                                <p>{data.farm.story||'-'}</p>
                                <div class="mt-7 text-right">
                                    <a href="#"><u>READ MORE</u></a>
                                </div>
                            </div>

                        </div>


                        <div className="seperator h-2 text-gray-600 flex justify-center items-center"></div>

                        <div className="location-body mx-2">
                            <div className="location-header p-3 section-title text-white text-center">
                                <p>LOCATION</p>
                            </div>
                            <div className="map-container relative w-full h-80" >
                                <div class="absolute top-0 left-0 w-full h-full" >
                                    <MapContainer lat={data.farm.latitude} long={data.farm.longitude}/>
                                </div>
                            </div>
                            <div className="location-content p-4">
                                <div className="flex">

                                    <div className="flex-1 px-3">
                                        <p class="font-semibold	text-xl">{data.company[0] ? (data.company[0].name ? data.company[0].name : '-') : '-'}</p>
                                        <p class="mb-3 text-base">{data.company[0] ? (data.company[0].address ? data.company[0].address : '-') : '-'}</p>
                                        

                                        <div className="flex mb-4">
                                            <img src={require("../assets/email.svg").default} class="w-5 mr-2"/>
                                            <div className="info self-center">
                                                <p class="text-sm"><u>{data.company[0] ? (data.company[0].email ? data.company[0].email : '-') : '-'}</u></p>
                                            </div>
                                        </div>

                                        <div className="flex mb-4">
                                            <img src={require("../assets/website.svg").default} class="w-5 mr-2"/>
                                            <div className="info self-center">
                                                <p class="text-sm"><u>{data.company[0] ? (data.company[0].website ? data.company[0].website : '-') : '-'}</u></p>
                                            </div>
                                        </div>
                                        <div className="flex mb-4">
                                            <img src={require("../assets/contact.svg").default} class="w-5 mr-2"/>
                                            <div className="info self-center">
                                                <p class="text-sm"><u>{data.company[0] ? (data.company[0].mobile ? data.company[0].mobile : '-') : '-'}</u></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-2">
                                        {(data.company[0] && data.company[0].facebook) && (
                                            <div className="flex mb-4" onClick={()=>{
                                                return window.open(data.company[0].facebook, '_blank', 'noopener,noreferrer')
                                            }}>
                                                <img src={require("../assets/facebook.svg").default} class="w-8 mr-2"/>
                                            </div>
                                        )}
                                        {(data.company[0] && data.company[0].instagram) && (
                                            <div className="flex mb-4" onClick={()=>{
                                                return window.open(data.company[0].instagram, '_blank', 'noopener,noreferrer')
                                            }}>
                                                <img src={require("../assets/instagram.svg").default} class="w-8 mr-2"/>
                                            </div>
                                        )}
                                        {(data.company[0] && data.company[0].youtube) && (
                                            <div className="flex mb-4" onClick={()=>{
                                                return window.open(data.company[0].youtube, '_blank', 'noopener,noreferrer')
                                            }}>
                                                <img src={require("../assets/youtube.svg").default} class="w-8 mr-2"/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            

                                {data.company[0] ? (data.company[0].mobile ? (
                                    <div className="row text-center">
                                        <p class="mb-4">TALK TO US</p>
                                        <div class="flex justify-evenly">
                                            {data.messenger.length > 0 && data.messenger.map( mes => {

                                                if (mes=='whatsapp' && data.company[0].mobile) {
                                                    return (
                                                        <div className="mb-4" onClick={()=>{
                                                            return window.open(`https://api.whatsapp.com/send?phone=${data.company[0].mobile}`, '_blank', 'noopener,noreferrer')
                                                        }}>
                                                            <img src={require("../assets/"+ mes +".svg").default} class="w-9 mr-2"/>
                                                        </div>
                                                    )
                                                } else if (mes=='fbmessenger' && data.company[0].facebook) {
                                                    return (
                                                        <div className="mb-4" onClick={()=>{
                                                            return window.open(`${data.company[0].facebook}`, '_blank', 'noopener,noreferrer')
                                                        }}>
                                                            <img src={require("../assets/"+ mes +".svg").default} class="w-9 mr-2"/>
                                                        </div>
                                                    )
                                                }  else if (mes=='telegram' && data.company[0].mobile) {
                                                    return (
                                                        <div className="mb-4" onClick={()=>{
                                                            return window.open(`https://t.me/${data.company[0].mobile}`, '_blank', 'noopener,noreferrer')
                                                        }}>
                                                            <img src={require("../assets/"+ mes +".svg").default} class="w-9 mr-2"/>
                                                        </div>
                                                    )
                                                }
                                            })}
                                            <div className="mb-4" >
                                                <img src={require("../assets/wechat.svg").default} class="w-9 mr-2"/>
                                            </div>
                                            <div className="mb-4" >
                                                <img src={require("../assets/line.svg").default} class="w-9 mr-2"/>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )) : (
                                    <></>
                                )}
                            </div>

                        </div>

                    </div>

                    <div className="footer fixed max-w-xl bottom-0 w-full">
                        <div className="flex w-full">
                            <div className="goldenrod flex w-6/12 p-2" onClick={()=>{
                                if (navigator.share) {
                                    navigator.share({
                                    title: 'Share',
                                    text: 'Share',
                                    url: `https://qr.farmsens.app/${id}`,
                                    })
                                    .then(() => console.log('Success!'))
                                    .catch((error) => console.log('Error', error));
                                }
                            }}>
                                <img src={require("../assets/share.svg").default} class="w-7 mr-2"/>
                                <p class="self-center text-white text-base">SHARE</p>
                            </div>
                            <Link to={`/feedback/${id}`} href="javascript:void(0);" className="yellowgreen flex w-6/12 p-2 fill-current text-white">
                                <img src={require("../assets/feedback.svg").default} class="w-7 mr-2"/>
                                <p class="self-center text-white text-base leading-none">SUBMIT FEEDBACK</p>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>

    )
}
