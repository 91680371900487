import React, { useEffect, useState } from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

function MapContainer({google, loaded, lat, long}) {
    const [data, setData] = useState({
        latitude: '-1.2884',
        longitude: '36.8233'
    })
    
    useEffect(() => {
        setData({
            latitude: lat,
            longitude: long,
        })
    }, [lat, long])

    return (
        <Map
        google={google}
        zoom={14}
        style={{
            width: '100%',
            height: '100%'
        }}
        center={{
            lat: data.latitude,
            lng: data.longitude
        }}
        >
        <Marker 
            position={{lat: data.latitude, lng: data.longitude}}
            name={''}/>
        </Map>
    );
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDnRuTj9djIMeirxzcobMDk2P5EzdIVErI'
})(MapContainer);