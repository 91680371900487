import React, {useEffect, useState} from 'react';
import Feedback from './pages/Feedback';
import Landing from './pages/Landing';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


function App() {

	return (
		<div  className="App" >
			<Router>
				<Switch>
				<Route exact path="/feedback/:id">
					<Feedback />
				</Route>
				<Route exact path="/:id">
					<Landing />
				</Route>
				<Route path="*">
					<p>Not Found</p>
				</Route>
				</Switch>
			</Router>

		</div>
	);
}

export default App;